
// This file contains the base url for the API
// This is used in the app.module.ts file
// in the that we have multiple servers we can use this file to change the token

import { environment } from "@environments/environment"

// refactor this use the old constants without the object
export type EnviromentType = keyof typeof ENVIROMENTS

export const HTTP = 'http://'

export const HTTPS = 'https://'

export const LOCAL_HOST = `${HTTP}127.0.0.1`

export const LUCA_SOLUTIONS = environment.url

export const LOCAL_KEVIN = `${HTTP}192.168.100.23`

export const ENVIROMENTS = {
  local: LOCAL_HOST,
  luca: LUCA_SOLUTIONS,
  localKevin: LOCAL_KEVIN
}

export const API_URL = (
    env: keyof typeof ENVIROMENTS | undefined,
    microservice: string,
    port?: string
) => {
  port = port ? `:${port}` : ''
  return `${ENVIROMENTS[environment.forceScope ? environment.scope : env ?? environment.scope]}${port}/${microservice}`
}

export const URLS = {
  AUTH_API_URL: API_URL(environment.scope, 'auth', environment.port ),
  EMPLOYEES_API_URL: API_URL(environment.scope, 'employee', environment.port),
  PACKAGES_API_URL: API_URL(environment.scope, 'packages', environment.port),
  CASH_BOX_API_URL: API_URL(environment.scope, 'cash-box', environment.port),
  CLIENTS_API_URL: API_URL(environment.scope, 'clients' , environment.port),
  SETTINGS_API_URL: API_URL(environment.scope, 'setting', environment.port),
  ARTICLES_API_URL: API_URL(environment.scope, 'articles', environment.port),
  FRANCHISE_API_URL: API_URL(environment.scope, 'franchise', environment.port),
  INVENTORY_API_URL: API_URL(environment.scope, 'inventory', environment.port),
  SALES_API_URL: API_URL(environment.scope, 'sales', environment.port),
  RECEIPTS_API_URL: API_URL(environment.scope, 'receipts', environment.port),
  REPORT_API_URL: API_URL(environment.scope, 'reports', environment.port),
  ROLES_API_URL: API_URL(environment.scope, 'roles', environment.port)
};

/**
 * @deprecated
 */
export function urls_factory(enviroment: keyof typeof ENVIROMENTS, port?: string) : Record<keyof typeof URLS, string> {
  const urls: Record<string, string> = {}
  const ms_regex = /\/([^/]+)$/

  const getMicroservice = (url: string) => url.match(ms_regex)
  const getPort = (url: string) => {
    const index = url.lastIndexOf(":") + 1
    const possiblePort = url.substring(index,  url.indexOf('/', index))
    return !isNaN(Number(possiblePort)) ? possiblePort : undefined
  }

  Object.entries(URLS).forEach(([key, url]) => {
    const _url = url.replace(url.includes(HTTP) ? HTTP : HTTPS, '')
    const microservice = getMicroservice(_url)
    if(microservice === (undefined || null) || microservice.length < 1)
      throw new Error(`Missing microservice or module for URL: ${url}`)

    port = port ?? getPort(_url)

    if(!port)
      throw new Error(`Port it is not provided [${microservice[1]}]`)

    urls[key] = API_URL(enviroment, microservice[1], port ?? getPort(_url));
  })
  return urls
}

